html {
  font-size: 100%; }

body {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 11px;
  line-height: 1.4em;
  background-color: #9EA2A2;
  padding: 20px; }

h1 {
  color: #554F47;
  margin: 13px 0 20px;
  font-size: 2em;
  vertical-align: baseline; }

h1, h2, h3, h4, h5, h6 {
  line-height: 1.1; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  color: #337ab7;
  text-decoration: none; }
  a:link, a:visited {
    color: #004E60; }
  a:hover {
    color: #554F47; }

body > .container {
  padding: 0; }

.login .wrapper {
  overflow: hidden;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 1px 1px 4px 1px #C8D8EB;
  margin: 0 auto;
  padding: 20px 20px 5px 17px;
  display: inline-block;
  max-width: 450px; }

.wrapper, .footer {
  text-align: center; }

header {
  padding: 12px 0; }
  header > img {
    margin-top: 10px; }

.help {
  margin: 20px 0; }
  .help h2 {
    font-weight: normal;
    font-size: 1.4em;
    margin: 0; }
  .help .separator-img {
    max-height: 40px; }

footer.footer {
  position: static;
  bottom: unset;
  width: auto;
  height: unset;
  line-height: unset;
  background-color: transparent; }
  footer.footer, footer.footer a {
    color: #FFF;
    font-size: 1.1em; }

.btn {
  font-size: inherit;
  padding: 10px 20px;
  border: 0;
  border-radius: 4px; }
  .btn.btn-submit {
    background-color: #ba0c2f;
    color: white; }
  .btn.btn-reset {
    background: #eee; }

.form-group {
  padding: 8px 0 16px;
  margin-bottom: 0; }
  .form-group:empty {
    display: none; }
  .form-group .form-input {
    font-size: 1.4em;
    padding: 5px;
    vertical-align: middle;
    border-radius: 0;
    display: inline-block;
    height: unset; }

.w-33 {
  width: 33.3%; }

.w-40 {
  width: 40%; }

.w-40 {
  width: 40%; }

.w-20 {
  width: 20%; }

hr {
  border-top: 1px solid #C8D8EB; }

.alert {
  border-radius: 0; }
  .alert, .alert a {
    color: #554F47; }
  .alert.alert-success {
    background-color: #DEFFAA;
    border-color: #DEFFAA; }

#duo_iframe {
  height: 360px !important;
  overflow: auto; }
